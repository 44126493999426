<template>
  <div class="rebate clearfix">
    <el-row type="flex" justify="space-between" class="rebate-row">
      <el-col :xs="24" :sm="12" :lg="11">
        <div class="item_header account_number">
          <span class="acc_left">
            {{ $t('home.RebateAccountNumber') }}
          </span>

          <AccountNumber ref="accountNumber" @accountCallback="init"></AccountNumber>
        </div>
        <el-card>
          <div class="flex flex-between flex-middle content">
            <div class="flex flex-column flex-center">
              <div>{{ $t('home.totalCommission') }}</div>
              <div v-textOverflow class="currency">
                <span>{{ currAccountCurrency | currencySymbol }}</span>
                {{ totalBalance | currency('') }}
              </div>
            </div>
            <router-link to="" @click.native="applyRebate" class="ib-btn el-button" data-testid="applyRebate">
              {{ $t('home.APPLY_FOR_A_REBATE') }}
            </router-link>
          </div>
        </el-card>
      </el-col>
      <el-col :span="2" class="flex flex-center flex-middle">
        <i class="el-icon-arrow-right"></i>
      </el-col>
      <el-col :xs="24" :sm="12" :lg="11">
        <div class="item_header">
          {{ $t('home.RebateAccountNumber') }}
          {{ accountID }}
        </div>
        <el-card>
          <div class="flex flex-between flex-middle content">
            <div class="flex flex-column flex-center">
              <span>{{ $t('home.availableBalance') }}</span>
              <span class="currency"
                >{{ currAccountCurrency | currencySymbol }} {{ availableBalance | currency('') }}</span
              >
            </div>
            <div class="flex btn-container">
              <a @click="checkBeforeWithdrawal()" class="el-button ib-btn" data-testid="WITHDRAW_REBATE">
                {{ $t('home.WITHDRAW_REBATE') }}
              </a>
              <a
                @click="goToRebateTransfer()"
                class="el-button ib-btn next-btn"
                data-testid="TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT"
              >
                {{ $t('home.TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT') }}
              </a>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <!-- apply for rebate 弹窗 -->
    <el-dialog :visible.sync="dialogVisible">
      <div class="dialog text-center">
        <em class="el-icon-circle-check text-green mb-3"></em>

        <p>{{ $tc('home.applyRebateSuccessTip', 0) }}</p>
        <p v-html="$tc('home.applyRebateSuccessTip', 1, { amount: appliedCommission, account: accountID })"></p>
        <p>{{ $tc('home.applyRebateSuccessTip', 2) }}</p>
      </div>
    </el-dialog>

    <WithdrawalFreezeDialog
      :freezeDialogVisible.sync="freezeDialogVisible"
      :fromFunctionCode="fromFunctionCode"
      :day="day"
      :hour="hour"
    >
    </WithdrawalFreezeDialog>
  </div>
</template>

<script>
import AccountNumber from '@/components/form/AccountNumber';
import { apiQueryAvailableBalance, apiApplyCommission, apiQueryBlacklist, apiQueryRebatesList } from '@/resource';
import blackList from '@/mixins/blackList';
import WithdrawalFreezeDialog from '@/components/WithdrawalFreezeDialog';
import { apiGetSecurity } from '@/resource';

export default {
  name: 'Rebate',
  components: { AccountNumber, WithdrawalFreezeDialog },
  mixins: [blackList],
  props: { userID: '' },
  data() {
    return {
      dialogVisible: false,
      appliedCommission: 0,
      availableBalance: 0,
      lang: this.$store.state.common.lang,
      freezeDialogVisible: false,
      withdrawalFreeze: false,
      day: 0,
      hour: 0,
      fromFunctionCode: ''
    };
  },
  computed: {
    accountID() {
      return this.$store.state.behavior.AID;
    },
    currAccountCurrency() {
      return this.$store.state.behavior.CUR;
    },
    totalBalance: {
      get() {
        return this.$store.state.behavior.balance;
      },
      set(value) {
        return this.$store.commit('behavior/setBalance', value);
      }
    }
  },
  methods: {
    // 获取 可用余额
    queryAvailableBalance() {
      if (!this.accountID) return;
      apiQueryAvailableBalance({
        qUserId: this.userID,
        qAccount: this.accountID
      }).then(resp => {
        if (resp.data.code === 0) this.availableBalance = resp.data.data.availableBalance;
      }, this.$handleError);
    },
    // 申请佣金
    async applyRebate() {
      if (!this.accountID) return;
      // 判断账号是否属于黑名单
      let result = await apiQueryRebatesList();
      if ((result.data.data.list.filter(item => item.login === this.accountID)[0] || []).isCommissionProhibit) {
        this.$message({
          type: 'error',
          message: this.$t('home.blacklist')
        });
        return;
      }
      apiApplyCommission({
        qAccount: this.accountID,
        qUserId: this.userID
      }).then(resp => {
        if (resp.data.code === 0) {
          this.appliedCommission = this.$options.filters.currency(this.totalBalance, '');
          this.dialogVisible = true;
          this.$refs.accountNumber.rebatesList.map((item, idx) => {
            if (item.login === this.accountID) item.commission = 0;
          });
          this.totalBalance = 0;
          //save for passing new commission among different pages
          this.bus.$emit('updateRebateHistory', true);
        }
      }, this.$handleError);
    },
    async getSecurityInfo() {
      await apiGetSecurity().then(result => {
        const resp = result?.data;

        if (resp?.code === 0) {
          if (resp?.data.length > 0) {
            this.fromFunctionCode = resp?.data[0]?.fromFunctionCode;
            this.day = resp?.data[0]?.limitDayNumber;
            this.hour = resp?.data[0]?.lastHours;
            this.withdrawalFreeze = true;
          } else {
            this.withdrawalFreeze = false;
          }
        }
      });
    },
    async checkBeforeWithdrawal() {
      await this.getSecurityInfo();

      if (!this.withdrawalFreeze) {
        this.goToRebateWithdraw();
      } else {
        this.freezeDialogVisible = true;
      }
    },
    // 内容初始化
    init() {
      // this.getSecurityInfo();
      this.queryAvailableBalance();
      this.$emit('accountIdAndCurrency', this.accountID, this.currAccountCurrency);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/rebate.scss';
</style>
